<template>
  <div class="league-view">
    <v-row>
      <v-col
        cols="12"
        class="col-md-3 col sm-6 col-lg-3 border"
      >
        <v-card>
          <v-card-title>
            <h4 class="text-center d-all mx-auto my-1">
              {{ league.name }}
            </h4>
          </v-card-title>
          <v-card-text>
            <div class="text-center">
              <v-avatar
                rounded
                size="125"
                height="125"
                color="white"
              >
                <v-img
                  height="120"
                  max-width="120"
                  lazy-src="https://www.fantasykombat.com/android-chrome-192x192.png"
                  :src="league.logo || 'https://www.fantasykombat.com/android-chrome-192x192.png'"
                />
              </v-avatar>
              <br>
              <v-chip
                v-if="league.desc"
                class="text-center mt-2"
              >
                {{ league.desc }}
              </v-chip>
              <br>
            </div>

            <div class="text-center mx-auto">
              <v-btn-toggle
                class="ma-2 d-block pa-1"
                dark
              >
                <span
                  v-if="league.partner"
                  class="mx-1"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ icons.mdiShieldStarOutline }}
                      </v-icon>
                    </template>
                    <span> Verified League</span>
                  </v-tooltip>
                </span>
                <span
                  v-if="league.strikeLeague"
                  class="mx-1"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ icons.mdiExpandAllOutline }}
                      </v-icon>
                    </template>
                    <span> Strike League</span>
                  </v-tooltip>
                </span>
                <span
                  v-if="league.mainEventOnly"
                  class="mx-1"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ icons.mdiListStatus }}
                      </v-icon>
                    </template>
                    <span> Main card only league</span>
                  </v-tooltip>
                </span>
                <span
                  v-else
                  class="mx-1"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ icons.mdiPlaylistCheck }}
                      </v-icon>
                    </template>
                    <span> Main Card & All Prelims</span>
                  </v-tooltip>
                </span>
                <span
                  v-if="league.joined"
                  class="mx-1"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ icons.mdiAccountMultipleCheckOutline }}
                      </v-icon>
                    </template>
                    <span>My League</span>
                  </v-tooltip>
                </span>
              </v-btn-toggle>

              <router-link
                v-if="admin || user.uid == league.createdBy"
                class="text-center d-block"
                :to="{ path: 'edit-league', query: { id:leagueId }}"
              >
                <v-btn
                  small
                  outlined
                  class="mt-3"
                >
                  Edit
                </v-btn>
              </router-link>

              <br>

              <div
                v-if="admin"
                class="text-xs"
              >
                {{ league.admins }}
              </div>
            </div>
            <hr class="my-4">
            <h4>
              <span class="hidden-sm-and-down">League</span> Rank:
              <v-chip>{{ rank|defaultDash }}</v-chip>
            </h4>
            <br>
            <h4>
              Total Points:
              <v-chip>{{ totalPoints }}</v-chip>
            </h4>
            <br>
            <h4>
              Last Event:
              <v-chip>{{ lastPoints }}</v-chip>
            </h4>
            <br>
            <h4 v-if="user.profile.admin">
              League Type:
              <v-chip>{{ league.visibility }}</v-chip>
            </h4>

            <div v-if="admin || user.uid == league.createdBy && league.kitty">
              <hr class="my-4">
              <v-btn
                v-if="admin"
                small
                outlined
                class="float-right"
                @click="kitty.show=true"
              >
                Kitty
              </v-btn>
              <h4>Kitty</h4>
              <v-list-item v-if="league.kitty">
                <v-avatar
                  :size="30"
                  class="white--text font-weight-medium me-2"
                >
                  <v-img :src="coinIconSilver"></v-img>
                </v-avatar>
                <div class="d-flex align-center flex-grow-1 flex-wrap">
                  <div class="me-1">
                    <div class="font-weight-semibold">
                      <span class="text--primary text-base me-1">
                        {{ league.kitty.skc|toCurrencyNoDec }}
                      </span>
                    </div>
                    <v-list-item-subtitle class="text-xs hidden-sm-and-down">
                      Silver KombatCoins
                    </v-list-item-subtitle>
                    <v-list-item-subtitle class="text-xs hidden-md-and-up">
                      SKC
                    </v-list-item-subtitle>
                  </div>
                </div>
              </v-list-item>
            </div>
            <hr class="my-4">
            <div class="embeds hidden-sm-and-down">
              <div
                v-if="league.discord"
                class="ma-2 mx-auto d-block text-center"
              >
                <a
                  :href="league.discord"
                  target="_blank"
                >
                  <v-btn
                    target="_blank"
                    outlined
                  >
                    <v-icon class="mr-1">
                      {{ icons.mdiDiscord }}
                    </v-icon>
                    Discord
                  </v-btn>
                </a>
              </div>
              <div
                class="my-3"
                v-html="league.embed1"
              ></div>
              <div
                class="my-3"
                v-html="league.embed2"
              ></div>
              <div
                v-if="league.twitter && league.twitter !== '@'"
                class="my-3"
                v-html="twitterFeed"
              ></div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        class="col-md-8 col-lg-8"
      >
        <div class="my-3">
          <v-data-table
            :headers="headers"
            :items="teams"
            :sort-by="['index']"
            :sort-desc="false"
            must-sort
            item-key="index"
            class="elevation-1 my-league-table"
            :search="search"
            mobile-breakpoint="0"
          >
            <template v-slot:top>
              <v-row>
                <v-col cols="4" class="col-md-6">
                  <v-text-field
                    v-model="search"
                    label="Search"
                    class="mt-1 pt-1 ml-4"
                  ></v-text-field>
                </v-col>
                <v-col cols="8" class="col-md-6">
                  <div class="my-auto text-right mt-1 mr-2 invite-btns">
                    <v-btn
                      v-if="league.visibility === 'Public' || joined"
                      class="mx-1"
                      outlined
                      small
                      color="success"
                      @click="createInviteData"
                    >
                      Invite
                    </v-btn>

                    <v-btn
                      v-if="joined"
                      class="mx-1"
                      outlined
                      small
                      @click="leaveLeague"
                    >
                      Leave
                    </v-btn>

                    <span v-if="myTeam.leagues">
                      <v-btn
                        v-if="!joined"
                        outlined
                        color="success"
                        class="ml-2"
                        small
                        @click="joinLeague"
                      >
                        Join
                      </v-btn>
                    </span>
                  </div>
                </v-col>
              </v-row>
            </template>

            <!-- name -->
            <template v-slot:item.name="{ item }">
              <div class="text-center my-3">
                <v-avatar
                  rounded
                  size="50"
                  height="70"
                  class="ma-2"
                  color="white"
                >
                  <v-img
                    class="text-center justify-center align-center d-all"
                    height="70"
                    max-width="50"
                    lazy-src="https://www.fantasykombat.com/android-chrome-192x192.png"
                    :src="item.logo || 'https://www.fantasykombat.com/android-chrome-192x192.png'"
                  />
                </v-avatar>

                <div class="text-center">
                  <v-chip class="league-name">
                    {{ item.name }}
                  </v-chip>
                </div>
                <div v-if="admin || user.uid == league.createdBy" class="my-1">

                  <v-btn class="mr-1" color="success" outlined
                         @click="giftMember(item)" x-small>
                    Gift
                  </v-btn>
                  <v-btn :disabled="confirmRemove" @click="removeMember(item)" color="error"
                         outlined
                         x-small
                  >Remove
                  </v-btn>

                  <div v-if="confirmRemove && checkId == item.id">
                    Remove {{ item.name }} from your league?<br>
                    <v-btn @click="removeMemberConfirmed(item)" x-small color="error"
                           class="ml-0 mt-1">
                      yes
                    </v-btn>
                    <v-btn @click="confirmRemove=false" x-small outlined class="ml-2 mr-0 mt-1">no
                    </v-btn>
                  </div>
                </div>
              </div>
              <span v-if="admin">{{ item.owner }}</span>
            </template>
            <template v-slot:item.index="{ item }">
              <div v-if="league.strikeLeague">
                <v-chip
                  class="rank-chip"
                  color="grey"
                  :class="{ 'silver': item.index <= 14, 'gold': item.index <=9 }"
                  small
                >
                  <v-icon
                    v-if="item.index===1"
                    small
                  >
                    {{ icons.mdiCrownOutline }}
                  </v-icon>
                  {{ item.index }}
                </v-chip>
              </div>
              <div v-else>
                <v-chip
                  class="rank-chip"
                  color="grey"
                  :class="{ 'silver': item.index <= 14, 'gold': item.index <=9 }"
                  small
                >
                  <v-icon
                    v-if="item.index===1"
                    small
                  >
                    {{ icons.mdiCrownOutline }}
                  </v-icon>
                  {{ item.index }}
                </v-chip>
              </div>
            </template>
            <template v-slot:item.lastPoints="{ item }">
              <div class="d-inline-block  mx-auto text-center">
                <div class="d-inline-block  mx-auto text-center">
                  <div class="d-inline-block mx-auto text-center">
                    {{
                      item.lastPoints
                    }}
                  </div>
                </div>
              </div>
            </template>

            <template v-slot:item.advisor="{ item }">
              <div class="text-center advisor mx-auto d-all">
                <v-avatar
                  v-if="item.advisor"
                  size="40"
                  class="white--text font-weight-medium mx-auto d-all"
                >
                  <v-img
                    :lazy-src="defaultAvatar"
                    :src="item.advisor.mainImage || defaultAvatar"
                  ></v-img>
                </v-avatar>
                <p class="my-2 mx-auto d-all text-wrap">
                  {{ item.advisor.name || '...' }}<br>
                </p>
              </div>
            </template>

            <template v-slot:item.captain="{ item }">
              <div class="text-center captain mx-auto d-all">
                <v-avatar
                  size="40"
                  class="white--text font-weight-medium"
                >
                  <v-img
                    v-if="item.captain.name"
                    :lazy-src="defaultAvatar"
                    :src="fighterImagesURL+'fighters/images/head-'+item.captain.slug+'_160x100.png'"
                  ></v-img>
                  <v-img
                    v-else
                    :lazy-src="defaultAvatar"
                    :src="defaultAvatar"
                  ></v-img>
                </v-avatar>
                <p class="my-2 text-center text-wrap">
                  {{ item.captain.name || '...' }}<br>
                </p>
              </div>
            </template>
          </v-data-table>
        </div>
        <v-card class="mt-5 hidden-md-and-up">
          <v-card-text>
            <div class="embeds">
              <div
                v-if="league.discord"
                class="my-3 mx-auto d-block text-center"
              >
                <v-btn
                  :to="league.discord"
                  target="_blank"
                  outlined
                >
                  <v-icon class="mr-1">
                    {{ icons.mdiDiscord }}
                  </v-icon>
                  Discord
                </v-btn>
              </div>
              <div
                class="my-3"
                v-html="league.embed1"
              ></div>
              <div
                class="my-3"
                v-html="league.embed2"
              ></div>
              <div
                v-if="league.twitter"
                class="my-3"
                v-html="twitterFeed"
              ></div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <Modal
      :modal="modal"
      class="mt-10"
    ></Modal>
    <v-dialog
      v-if="admin"
      v-model="kitty.show"
      width="500"
    >
      <v-card :modal-open="kitty">
        <v-card-title class="text-h6 grey lighten-2">
          Kitty
        </v-card-title>

        <v-card-text>
          Add Coins
          <v-currency-field
            v-model="kitty.skc"
            label="Silver"
          ></v-currency-field>
          <v-currency-field
            disabled
            v-model="kitty.gkc"
            label="Gold"
          ></v-currency-field>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>

          <v-spacer></v-spacer>
          <v-btn
            color="success"
            outlined
            @click="saveKitty"
          >
            Add to kitty
          </v-btn>
          <v-btn
            color="error"
            outlined
            @click="kitty.show = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="league.createdBy==user.uid || admin"
      v-model="gift.show"
      width="500"
    >
      <v-card :modal-open="gift">
        <v-card-title class="text-h6 grey lighten-2">
          Send <strong class="mx-1">{{ gift.team }}</strong> a Gift
        </v-card-title>
        <v-card-text>
          <v-select :items="giftType" v-model="gift.rewardType" placeholder="Type"></v-select>
          <v-text-field v-model="gift.desc"
                        placeholder="Description"
                        Label="Description">
          </v-text-field>
          <v-text-field v-if="gift.rewardType=='Prize'"
                        placeholder="Event"
                        Label="Event"
                        v-model="gift.event"></v-text-field>
          <v-currency-field
            v-model="gift.skc"
            label="Silver Kombat Coins"
          ></v-currency-field>
          <v-currency-field
            disabled
            v-model="gift.gkc"
            label="Gold Kombat Coins"
          ></v-currency-field>
          <v-select placeholder="Send Asset" disabled></v-select>
          <v-text-field v-model="gift.from" label="from" placeholder="from">
          </v-text-field>
          <div v-if="lowBalance" class="error--text">Kitty balance too low.</div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>

          <v-spacer></v-spacer>
          <v-btn
            color="success"
            outlined
            @click="sendGift()"
          >
            Send {{ gift.rewardType || 'Gift' }}
          </v-btn>
          <v-btn
            color="error"
            outlined
            @click="gift.show = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  mdiAccountMultipleCheckOutline,
  mdiAlert,
  mdiCrownOutline,
  mdiExpandAllOutline,
  mdiListStatus,
  mdiPlaylistCheck,
  mdiShieldStarOutline,
  mdiDiscord,
} from '@mdi/js'
import dbFunctions from '@/db'
import Modal from '@/views/components/Modal.vue'
import db from '@/db/init'

export default {
  components: {
    Modal,
  },
  setup() {
    return {
      icons: {
        mdiAlert,
        mdiCrownOutline,
        mdiShieldStarOutline,
        mdiExpandAllOutline,
        mdiPlaylistCheck,
        mdiListStatus,
        mdiAccountMultipleCheckOutline,
        mdiDiscord,
      },
    }
  },
  data() {
    return {
      user: this.$store.state.user,
      admin: this.$store.state.user.profile?.admin,
      defaultAvatar: 'https://static.fantasykombat.com/images/default-profile-head.png',
      checkId: '',
      search: '',
      joined: '',
      rank: '',
      league: {},
      teams: [],
      coinIconSilver: require('@/assets/images/buy/KombatCoinSilver.png'),
      lastPoints: 0,
      totalPoints: 0,
      lastPowerUpPoints: 0,
      totalPowerUpPoints: 0,
      myTeam: [],
      mailto: '',
      inviteCode: '',
      inviteUrl: '',
      leagueId: null,
      logos: '',
      isVisible: true,
      country: {},
      twitterFeed: '',
      actions: false,
      confirmRemove: false,
      giftType: ['Prize', 'Gift'],
      lowBalance: false,
      gift: {
        show: false,
        claimType: '',
        event: '',
        desc: '',
        skc: 0,
        gkc: 0,
        rewardType: '',
        from: this.$store.state.user.displayName,
      },
      kitty: {
        show: false,
        skc: 0,
        gkc: 0,
      },
      modal: {
        title: 'Invite friends and foe to your League.',
        content: 'place holder content',
        show: false,
      },
    }
  },
  metaInfo() {
    return {
      title: `My FantasyKombat League - ${this.league.name}`,
      meta: [
        {property: 'og:url', content: this.inviteUrl},
      ],
    }
  },
  computed: {
    headers() {
      let headers = []
      if (this.league.strikeLeague) {
        headers = [
          {text: 'Team', align: 'start', value: 'name'},
          {text: 'Rank', value: 'index'},

          // {text: 'Total Points', value: 'totalCombined'},
          {text: 'Total', value: 'lastCombined'},
          {text: 'PowerUps', value: 'lastPowerUpPoints'},
          {text: 'Points', value: 'lastPoints'},
          {text: 'Advisor', value: 'advisor'},
          {text: 'Captain', value: 'captain'},
        ]
      } else {
        headers = [
          {text: 'Team', align: 'start', value: 'name'},
          {text: 'Rank', value: 'index'},

          // {text: 'Total Points', value: 'totalPoints'},
          {text: 'Lastest', value: 'lastPoints'},
          {text: 'Advisor', value: 'advisor'},
          {text: 'Captain', value: 'captain'},
        ]
      }

      return headers
    },
  },
  mounted() {
    this.rank = this.$route.query.rank
    this.leagueId = this.$route.query.id
    this.getLeague()
    this.getMyTeam()
    this.getTeams()
  },
  methods: {
    showActions() {
      this.confirmRemove = false
      this.actions = !this.actions
    },
    removeMember(item) {
      this.checkId = item.id
      this.confirmRemove = !this.confirmRemove
    },
    removeMemberConfirmed(item) {
      dbFunctions.removefromLeague(this.leagueId, item.id).then(() => {
        this.teams.splice(this.teams.findIndex(e => e.id === item.id), 1)
        this.confirmRemove = false
        this.checkId = ''
      })
    },
    async saveKitty() {
      this.kitty.show = false
      const updatedKitty = {
        skc: this.league.kitty?.skc || 0 + this.kitty.skc,
        gkc: this.league.kitty?.gkc || 0 + this.kitty.gkc,
      }
      this.league.kitty = updatedKitty
      const leagueData = {kitty: updatedKitty}
      await db.collection('leagues')
        .doc(this.leagueId)
        .update(leagueData)
        .then(() => {
          this.league.kitty = updatedKitty
        })
        .catch(error => {
          console.log(error)
        })
    },
    giftMember(item) {
      this.lowBalance = false
      this.gift.id = item.id
      this.gift.show = true
      this.gift.team = item?.name
    },
    sendGift() {
      this.lowBalance = false
      if (this.gift.id) {
        let balanceOK = false
        if (this.league?.kitty) {
          if (this.gift.skc) {
            this.gift.claimType = 'skc'
            if (this.gift.skc <= this.kitty?.skc || 0) {
              balanceOK = false
            } else {
              balanceOK = true
              this.league.kitty.skc -= this.gift.skc
            }
          }
          if (this.gift.gkc) {
            this.gift.claimType = 'gkc'
            if (this.gift.gkc <= this.kitty?.gkc || 0) {
              balanceOK = false
            } else {
              balanceOK = true
              this.league.kitty.gkc -= this.gift.gkc
            }
          }
        }
        if (balanceOK) {
          dbFunctions.addClaim(this.gift.id, this.gift.claimType, this.gift.event, this.gift.desc, this.gift.skc, this.gift.gkc, this.gift.rewardType, this.gift.from)
            .then(async () => {
              const leagueData = {kitty: this.league.kitty}
              await db.collection('leagues')
                .doc(this.leagueId)
                .update(leagueData)
                .catch(error => {
                  console.log(error)
                })
              this.gift.show = false
            })
        } else {
          this.lowBalance = true
        }
      }
    },
    getMyTeam() {
      dbFunctions.getTeam(this.$store.state.user.uid)
        .then(teamData => {
          this.myTeam = teamData
          this.joined = this.myTeam.leagues.indexOf(this.leagueId) !== -1
        })
    },
    async joinLeague() {
      this.myTeam.leagues.push(this.leagueId)
      this.myTeam.leagues = [...new Set(this.myTeam.leagues)]
      await dbFunctions.updateTeam(this.$store.state.user.uid, {leagues: this.myTeam.leagues})
        .then(() => {
          console.log('joined')
          this.joined = true
          this.teams = []
          this.getTeams()

          // this.$router.go()
        })
    },
    leaveLeague() {
      const index = this.myTeam.leagues.indexOf(this.leagueId)
      if (index > -1) {
        this.myTeam.leagues.splice(index, 1)
      }
      this.myTeam.leagues = [...new Set(this.myTeam.leagues)]
      dbFunctions.updateTeam(this.$store.state.user.uid, {leagues: this.myTeam.leagues})
        .then(() => {
          this.$router.push({path: '/leagues'})
        })
    },
    async copyURL(text) {
      try {
        await navigator.clipboard.writeText(text)
      } catch ($e) {
        console.log('Cannot copy')
      }
    },
    createInviteData() {
      /* eslint-disable-next-line */
      this.mailto = `mailto:friend@email.com?subject=Link%20to%20my%20FantasyKombat%20League&body=Hey%2C%0A%0AHere%20is%20the%20link%20to%20my%20FantasyKombat%20League%3A%0A%0ALink%3A%20${this.emailInviteUrl}%0A%0AInvite%20Code%3A%20${this.inviteCode}`
      this.modal.content = `
      <div v-if="league.visibility === 'Public' || myTeam.leagues.indexOf(leagueId) === -1"><br>
        <h4>Share Invite Link:</h4>
        <span id="copyUrl">${this.inviteUrl}</span>
         <div class="text-center">
        <br>
                <button id="copyUrlBTN"class="v-btn v-btn--outlined theme--dark v-size--default mr-2">Copy Link</button><a href="${this.mailto}" class="v-btn v-btn--outlined theme--dark v-size--default ml-2"> Email </a>
                <br><strong class="float-right d-none" id="copied1">Copied</strong>
        </div>
      </div>
      <br>
      <h3 class="text-center mt-1">Or</h3>
      <div v-if="league.visibility
       === 'Public' || myTeam.leagues.indexOf(leagueId) === -1"><br>
        <h4>Share League Code:</h4><span id="copyCode">${this.inviteCode}</span>
        <br>
        <div class="text-center">
        <br>
        <button id="copyCodeBTN" class="v-btn v-btn--outlined theme--dark v-size--default">Copy League Code</button>
        </div>
        <br><strong class="float-right d-none" id="copied2">Copied</strong>
      </div>
        <br>
      <br>
       <div class="sharethis-inline-share-buttons" data-url="${this.inviteUrl}" data-description ="A next-gen Fantasy Sports game for MMA / UFC.

Recruit, Train, Fight, Pr


ofit" data-title="Join My FantasyKombat League:" data-image="https://www.fantasykombat.com/android-chrome-512x512.png"></div>

      <br><br>`
      this.modal.show = true
      const copyUrl = this.copyURL
      const copiedText = ''
      setTimeout(() => {
        const copyLink = document.getElementById('copyUrl')
        copyLink.addEventListener('click', function () {
          this.innerHTML = this.innerHTML.replace(copiedText, '')
          copyUrl(this.innerHTML)
        })
        document.getElementById('copyUrlBTN').addEventListener('click', () => {
          const copyCodeText = copyLink.innerHTML
          copyUrl(copyCodeText)
          document.getElementById('copied1').classList.remove('d-none')
        })
        const copyCode = document.getElementById('copyCode')
        copyCode.addEventListener('click', function () {
          this.innerHTML = this.innerHTML.replace(copiedText, '')
          copyUrl(this.innerHTML)
          this.innerHTML += copiedText
        })
        document.getElementById('copyCodeBTN').addEventListener('click', () => {
          const copyCodeText = copyCode.innerHTML
          copyUrl(copyCodeText)
          document.getElementById('copied2').classList.remove('d-none')
        })
        /* eslint-disable-next-line */
        window.__sharethis__.initialize()
      }, 1000)
    },
    getLeague() {
      if (this.leagueId) {
        dbFunctions.getMyLeagues(this.leagueId)
          .then(leagueData => {
            this.league = leagueData.data()
            if (this.league.country) {
              this.country = this.league.country
            }
            this.twitterFeed = `<a class="twitter-timeline" data-height="500" data-theme="dark" data-chrome="nofooter noheader" href="https://twitter.com/${this.league.twitter}?ref_src=twsrc%5Etfw">Tweets by ${this.league.twitter}</a>`
            const twitterScript = document.createElement('script')
            twitterScript.setAttribute('src', 'https://platform.twitter.com/widgets.js')
            document.head.appendChild(twitterScript)

            this.inviteCode = `${this.leagueId}-${this.league.inviteCode}`
            this.inviteUrl = `${window.location.origin}/register/?leagueCode=${this.inviteCode}`
            this.emailInviteUrl = `${window.location.origin}%2Fregister%2F%3FleagueCode%3D${this.inviteCode}`

            return leagueData
          })
      } else {
        this.league = {
          name: '',
          desc: '',
          status: 'active',
          logo: '',
          visibility: 'Public',
          country: '',
        }
      }
    },
    async getTeams() {
      this.lastPoints = 0
      this.totalPoints = 0
      this.lastPowerUpPoints = 0
      this.totalPowerUpPoints = 0
      let last = 0
      let totalPoints = 0
      let teams = []
      await dbFunctions.getLeagueTeams(this.leagueId)
        .then(TeamsData => {
          if (this.league.strikeLeague) {
            teams = TeamsData.sort((a, b) => (a.lastCombined || 0) - (b.lastCombined || 0)).reverse()
          } else {
            teams = TeamsData.sort((a, b) => (a.lastPoints || 0) - (b.lastPoints || 0)).reverse()
          }
          for (let i = 0; i < teams.length; i += 1) {
            teams[i].index = i + 1
            this.teams.push(teams[i])
            last += this.teams[i].lastPoints || 0
            totalPoints += this.teams[i].totalPoints || 0
            if (this.league.strikeLeague) {
              this.lastPoints += this.teams[i].lastCombined || 0
              this.totalPoints += this.teams[i].totalCombined || 0
            } else {
              this.lastPoints = last
              this.totalPoints = totalPoints
            }
          }
          dbFunctions.saveLeaguePoints(this.leagueId, last, totalPoints)
        })
    },
  },
}
</script>

<style lang="scss">

.mobile .my-league-table td.text-start {
  padding: 0px !important;
}

button#copyUrlBTN {
  cursor: pointer;
}

span.team-name {
  background: #000 !important;
  color: #ffff;
  padding: 2px;
  z-index: 2;
  bottom: 5px;
  width: 120px;
  height: 40px;
  font-family: 'Black Ops One', 'Helvetica Neue', Helvetica, Arial, sans-serf, serif;
  border-radius: 0 0 4px 4px;
  display: inline-block;
  text-align: center;
  margin: auto;

}

.v-avatar span.league-name, .v-avatar span.team-name {
  position: absolute;
}

span.team-text-wrapper {
  width: 110px;
  height: 30px;
  display: inline-block;
  margin-top: 6px;
  font-size: 12px;
}

span.team-text {
  text-align: center;
  font-size: 14px;
}

.team-avatar-img {
  margin-top: -35px;
  display: inline-block;
}

.mobile .invite-btns .v-btn--outlined.theme--dark.v-size--default {
  min-width: 70px !important;
  width: 60px !important;
}

.embeds iframe {
  width: 100%;
  border-radius: 10px;
}

</style>
